import React, { useContext, useEffect, useMemo } from "react";
import { ButtonGroup, Modal, Button } from "react-bootstrap";
import { CartContext, LocationsContext } from "../../context";
import _ from "lodash";
import { useSessionStorage } from "../../hooks";
import parse from "url-parse";
import { useMixpanel } from "gatsby-plugin-mixpanel";
import { useTrans } from "../../hooks";

const ExternalDeliveryModal = () => {
  const {
    selectedLocation,
    isOpenExternalDeliveryModal,
    setIsOpenExternalDeliveryModal,
  } = useContext(LocationsContext);
  const mixpanel = useMixpanel();
  const transMsg = useTrans();
  const [seen, setSeen] = useSessionStorage(
    "ExternalDeliveryModalHasBeenSeen",
    false
  );
  const cartContext = useContext(CartContext);
  const onHide = () => {
    setIsOpenExternalDeliveryModal(!isOpenExternalDeliveryModal);
    setSeen(true);
  };

  useEffect(() => {
    if (_.isEmpty(selectedLocation.externalDeliverylink)) return;

    if (seen) return;
    setIsOpenExternalDeliveryModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  const onMenuAndPickUp = () => {
    cartContext.setOrderType("pickup");
    if (process.env.MIXPANEL_API_TOKEN) mixpanel.track("pickupSelected");
    onHide();
  };

  const deliveryMethod = useMemo(() => {
    if (_.isEmpty(selectedLocation?.externalDeliverylink)) return "";

    return _.capitalize(
      _.last(
        _.dropRight(
          _.split(
            parse(selectedLocation.externalDeliverylink, true).hostname,
            "."
          )
        )
      )
    );
  }, [selectedLocation]);
  return (
    <Modal
      show={isOpenExternalDeliveryModal}
      onHide={onHide}
      centered
      backdrop="static"
    >
      <Modal.Header className="row">
        <div className="col-12 pb-4 text-center">
          {transMsg("externalDeliveryMessage", {
            deliveryMethod: <b>{deliveryMethod}</b>,
          })}
        </div>

        <div className="col-12 text-center">
          <ButtonGroup vertical>
            <a
              id="anchorID"
              href={selectedLocation?.externalDeliverylink || "/"}
              target="_blank"
              className="w-100"
              rel="noreferrer"
            >
              <Button
                type="button"
                variant="outline-info"
                className="w-100 mb-2"
                onClick={() => {
                  if (process.env.MIXPANEL_API_TOKEN)
                    mixpanel.track("deliverySelected");
                }}
              >
                {transMsg("delivery")}
              </Button>
            </a>
            <Button
              type="button"
              onClick={onMenuAndPickUp}
              variant="outline-primary"
              className="w-100 mt-2"
            >
              {transMsg("menuAndPickUp")}
            </Button>
          </ButtonGroup>
        </div>
      </Modal.Header>
    </Modal>
  );
};
export default ExternalDeliveryModal;
