import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup } from "react-bootstrap";
import Icofont from "../IcoFont";
import _ from "lodash";
import { usePriceFormat } from "../../hooks";
import { useTrans } from "../../hooks";

const CheckoutItem = ({
  id,
  itemName,
  price,
  image,
  imageAlt,
  quantity,
  editable,
  max,
  min,
  getValue,
  imageClass,
  priceUnit,
  choices,
  note,
}) => {
  const transMsg = useTrans();
  const { priceFormat } = usePriceFormat();
  const IncrementItem = () => {
    if (quantity >= max) {
    } else {
      getValue({
        id,
        quantity: quantity + 1,
      });
    }
  };
  const DecreaseItem = () => {
    if (quantity <= min) {
    } else {
      getValue({
        id,
        quantity: quantity - 1,
      });
    }
  };

  const [showRemove, setShowRemove] = useState(false);

  return (
    // TODO: FIX OVERLAP OF SEARCH BAR
    <div className="gold-members p-2 border-bottomOFF ">
      <p className="text-gray mb-0 float-right ml-2 text-black font-14">
        {priceFormat(price * quantity)}
      </p>
      <span className="count-number-round float-left">
        {editable && (
          <>
            <ButtonGroup>
              {/* {quantity > 0 ? ( */}
              <React.Fragment>
                <Button
                  hidden={showRemove}
                  variant="light"
                  onClick={
                    quantity === 1 ? () => setShowRemove(true) : DecreaseItem
                  }
                  // size="lg"
                  className="minBtn minBtn-drop sm-cart"
                >
                  <Icofont icon="minus" />
                </Button>
                <div
                  className="addCartBtn addCartBtn-sm sm-cart text-center"
                  hidden={showRemove}
                >
                  {quantity}
                </div>
                <Button
                  hidden={showRemove}
                  variant="light"
                  onClick={IncrementItem}
                  size="sm"
                  className="addBtn addBtn-drop sm-cart mr-2"
                >
                  <Icofont icon="plus" />
                </Button>
              </React.Fragment>
              {/* ) : ( */}
              <React.Fragment>
                <Button
                  hidden={!showRemove}
                  className="mr-2"
                  onClick={DecreaseItem}
                  style={{
                    width: "81px",
                    height: "30px",
                    borderRadius: "25px",
                    backgroundColor: "#E61400",
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      fontSize: "13px",
                    }}
                    hidden={!showRemove}
                  >
                    {transMsg("remove")}
                  </div>
                </Button>
              </React.Fragment>
              {/* )} */}
            </ButtonGroup>
          </>
        )}
      </span>
      <div className="media">
        <div className="mr-2OFF"></div>
        <div className="media-body">
          <div className="mt-1 mb-0 text-black capitalize font-14">
            {itemName}
          </div>

          {!_.isEmpty(choices) && (
            <ul className="">
              {choices.map((c, i) => {
                return (
                  <React.Fragment key={i}>
                    <li>{c.item.name}</li>
                  </React.Fragment>
                );
              })}
            </ul>
          )}
          <div className="mt-1 mb-0 text-grey capitalize">{note}</div>
        </div>
      </div>
    </div>
  );
};

CheckoutItem.propTypes = {
  itemName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceUnit: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired,
  imageAlt: PropTypes.string,
  image: PropTypes.string,
  imageClass: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  getValue: PropTypes.func.isRequired,
};
CheckoutItem.defaultProps = {
  editable: true,
  imageAlt: "",
  imageClass: "",
  priceUnit: "$",
  quantity: 0,
  max: 99,
  min: 0,
};

export default CheckoutItem;
