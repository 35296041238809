import React, { useContext, useState } from "react";
import { usePriceFormat } from "../../hooks";
import { CartContext } from "../../context";

import CartModal from "../../components/modals/CartModal";
import { useTrans } from "../../hooks";

const CartCheckout = () => {
  const transMsg = useTrans();
  const { cartTotals } = useContext(CartContext);
  const [showCart, setShowCart] = useState(false);
  const handleClose = () => setShowCart(false);
  const handleShow = () => setShowCart(true);

  const { priceFormat } = usePriceFormat();

  // ================================================================
  return (
    <>
      {cartTotals?.qty > 0 && (
        <div
          className="generator-bgOFF rounded shadow-sm mb-4OFF p-4 osahan-cart-item cart-fix noselect"
          onClick={handleShow}
          role="button"
          tabIndex={0}
          onKeyPress={() => {}}
        >
          <p className="mb-1 text-black font-24 text-center bold">
            {transMsg("viewBag")} <i className="fa bag-icon">&#xf290;</i>
            <span className="badge badge-danger round" id="lblCartCount">
              {" "}
              {cartTotals.qty}
            </span>
            <span> &#8226; </span>
            <span className="bold">{priceFormat(cartTotals?.subtotal)}</span>
          </p>
        </div>
      )}

      <CartModal show={showCart} onHide={handleClose} />
    </>
  );
};

export default CartCheckout;
