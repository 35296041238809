import React, { useContext } from "react";
import { Modal, NavDropdown } from "react-bootstrap";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { CartContext, LocationsContext } from "../../context";
import _ from "lodash";
import CheckoutItem from "../common/CheckoutItem";
import CartDropdownHeader from "../cart/CartDropdownHeader";
import { usePriceFormat, useUpdateCartQty } from "../../hooks";
import CannotDeliverMessage from "../CannotDeliverMessage";
import { useTrans } from "../../hooks";

const CartModal = ({ show, onHide }) => {
  const transMsg = useTrans();
  const { cartLineItems, cartTotals, orderType } = useContext(CartContext);
  const locationsContext = useContext(LocationsContext);
  const { priceFormat, priceSymbol } = usePriceFormat();
  const getQty = useUpdateCartQty();

  // =========================================================
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton className="ml-2">
          <Modal.Title>{transMsg("yourOrder")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CannotDeliverMessage />
          <div className="dropdown-cart-top">
            {
              <CartDropdownHeader
                orderTitle=""
                className="dropdown-cart-top-header p-4"
                title={locationsContext?.selectedLocation?.name || ""}
                subTitle={
                  locationsContext?.selectedLocation?.address?.address_1 +
                  ", " +
                  locationsContext?.selectedLocation?.address?.city
                }
                NavLinkUrl="#"
                NavLinkText={transMsg("viewFullMenu")}
              />
            }
            <div className="dropdown-cart-top-body border-top p-4 ">
              {_.isEmpty(cartLineItems) ? (
                <p className="text-14 text-grey">
                  {transMsg("emptyCartMessage")}
                </p>
              ) : null}

              {!_.isEmpty(cartLineItems) &&
                _.map(cartLineItems, (v, k) => {
                  return (
                    <CheckoutItem
                      key={k}
                      itemName={v.title}
                      price={v.unitPrice}
                      priceUnit={priceSymbol}
                      id={v.id}
                      quantity={v.quantity}
                      show={true}
                      minValue={0}
                      maxValue={99}
                      getValue={getQty}
                      choices={v.choices}
                      note={v.note}
                    />
                  );
                })}

              <hr />

              <div className="row">
                <div className="col-6">{transMsg("itemTotal")}</div>
                <div className="col-6 text-right">
                  {priceFormat(cartTotals?.subtotal)}
                </div>
              </div>
              {orderType === "delivery" &&
                !_.isUndefined(cartTotals.deliveryFees) && (
                  <>
                    <div className="row mt-2">
                      <div className="col-6">{transMsg("delivery")}</div>
                      <div className="col-6 text-right">
                        {priceFormat(cartTotals.deliveryFees)}
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>

          {!_.isEmpty(cartLineItems) && (
            <div className="dropdown-cart-top-footer border-topOFF p-2OFF">
              <NavDropdown.Item
                eventKey={5.1}
                as={Link}
                className="btn btn-warning btn-block py-3 text-dark text-center dropdown-item btn-checkout bold"
                to="/checkout"
              >
                <div style={{ height: "20px" }}>
                  <span className="text-center">{transMsg("checkout")}</span>
                </div>
              </NavDropdown.Item>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CartModal;
