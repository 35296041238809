import React, { useContext } from "react";
import { MenuContext } from "../react-flexible-sliding-menu";
import { Nav, Button } from "react-bootstrap";
import { Link } from "gatsby";
import Icofont from "../IcoFont";
import { UserAuthContext, CustomerContext } from "../../context";
import LocalesComponent from "../LocalesComponent";
import { LocalizedLink } from "gatsby-theme-i18n";
import { useTrans } from "../../hooks";
import BetterRestoLogo from "../BetterRestoLogo";

function Menu({ showShareLocations }) {
  const { isAuthenticated } = useContext(UserAuthContext);
  const { closeMenu } = useContext(MenuContext);
  const transMsg = useTrans();
  const { customer } = useContext(CustomerContext);

  return (
    <div className="Menu">
      <br />
      <br />
      {isAuthenticated && (
        <>
          <Link to="/myaccount">
            <span className="ml-1 text-black font-14">
              {/* TODO: FIX NAME */}
              {customer.displayName || customer.email || " "}
            </span>
            <hr />
          </Link>
        </>
      )}
      {!isAuthenticated && (
        <React.Fragment>
          <Button
            as={LocalizedLink}
            to="/login"
            variant="warning"
            className="text-black font-14 sign-in-btn"
            style={{ borderRadius: "25px", padding: "0px" }}
          >
            {transMsg("signIn")}
          </Button>
          <br />
          <br />
        </React.Fragment>
      )}
      <Nav.Link
        className="text-left nav-item signin-btn mb-2 menu-nav-item"
        eventKey={2}
        as={LocalizedLink}
        activeclassname="active"
        to="/"
      >
        <Icofont className="mr-3 icon-size" icon="home" />
        {transMsg("home")}
      </Nav.Link>

      {isAuthenticated && (
        <React.Fragment>
          <Nav.Link
            className="text-left nav-item signin-btn mb-2 menu-nav-item"
            eventKey={2}
            as={Link}
            activeclassname="active"
            to="/myaccount/orders"
          >
            <Icofont className="mr-3 icon-size" icon="page" />
            {transMsg("orders")}
          </Nav.Link>
          <Nav.Link
            className="text-left nav-item signin-btn mb-2 menu-nav-item"
            eventKey={2}
            as={Link}
            activeclassname="active"
            to="/myaccount/addresses"
          >
            <Icofont className="mr-3 icon-size" icon="location-pin" />
            {transMsg("addresses")}
          </Nav.Link>
          <Nav.Link
            className="text-left nav-item signin-btn mb-2 menu-nav-item"
            eventKey={2}
            as={Link}
            activeclassname="active"
            to="/logout"
          >
            <Icofont className="mr-3 icon-size" icon="sign-out" />
            {transMsg("signOut")}
          </Nav.Link>
        </React.Fragment>
      )}

      {!isAuthenticated && (
        <Nav.Link
          className="text-left nav-item mb-2"
          eventKey={3}
          as={LocalizedLink}
          activeclassname="active"
          to="/register"
        >
          <Icofont className="mr-3 icon-size" icon="paperclip" />
          {transMsg("register")}
        </Nav.Link>
      )}

      <hr />

      {showShareLocations && (
        <Nav.Link
          className="text-left nav-item mb-2"
          eventKey={3}
          as={LocalizedLink}
          activeclassname="active"
          to="/locations"
        >
          <Icofont className="mr-3 icon-size" icon="location-pin" />
          {transMsg("locations")}
        </Nav.Link>
      )}

      <LocalesComponent
        showIcone={true}
        displayShort={false}
        linkClassName="text-left nav-item mb-2 nav-link"
      />

      <button className="menu-x-btn" onClick={closeMenu}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </button>

      <span className="push-to-bottom">
        <small className="mt-0 mb-0">
          {" "}
          {transMsg("poweredBy")}
          <a
            rel="noreferrer"
            className="ml-1 theme-color-text"
            target="_blank"
            href={`https://www.betterresto.com/`}
          >
            <BetterRestoLogo fontSize={12} />
          </a>
        </small>
      </span>
    </div>
  );
}

export default Menu;
