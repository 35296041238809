import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Icofont from "../IcoFont";

class CartDropdownHeader extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        {this.props.orderTitle !== "" ? (
          <left>
            <h5 className="mb-3">{this.props.orderTitle}</h5>
            <br />
          </left>
        ) : null}
        {/* <hr /> */}
        {this.props.image ? (
          <img
            alt=""
            src={this.props.image}
            className={this.props.imageClass}
          />
        ) : (
          ""
        )}
        <div className="multi-line">
          <Icofont
            icon="location-pin"
            style={{
              fontSize: "24px",
              color: "#E61400",
              marginRight: "4px",
            }}
          />
          <div>
            {" "}
            <h6 className="mb-2">{this.props.title}</h6>
            {this.props.subTitle ? (
              <h6 className="text-secondary mb-0 thin-text">
                {this.props.subTitle}
              </h6>
            ) : (
              ""
            )}
          </div>
        </div>

        {this.props.linkUrl ? (
          <small>
            <Link
              className="text-primary font-weight-bold"
              to={this.props.linkUrl}
            >
              {this.props.linkText}
            </Link>
          </small>
        ) : (
          ""
        )}
      </div>
    );
  }
}

CartDropdownHeader.propTypes = {
  title: PropTypes.string.isRequired,
  orderTitle: PropTypes.string,
  subTitle: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.string,
  imageClass: PropTypes.string,
  imageAlt: PropTypes.string,
  badgeClass: PropTypes.string,
  badgeValue: PropTypes.number,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
};

CartDropdownHeader.defaultProps = {
  orderTitle: "",
  subTitle: "",
  className: "",
  imageAlt: "",
  image: "",
  imageClass: "",
  badgeClass: "",
  badgeValue: 0,
  linkUrl: "",
  linkText: "",
};

export default CartDropdownHeader;
