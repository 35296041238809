import React, { useEffect, useContext, useState } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";

import { CartContext, LocationsContext } from "../../context";
import _ from "lodash";
import AddressInput from "../AddressInput";

import ScheduleTime from "../ScheduleTime";
import CannotDeliverMessage from "../CannotDeliverMessage";
import { useCreateCart, useUpdateCart } from "../../hooks";
import NewAutoAddressInput from "../AddressInput/newAutocomplete";
import { useTrans } from "../../hooks";
import produce from "immer";

const DeliveryAddressModal = ({ show, onHide }) => {
  const transMsg = useTrans();
  const cartContext = useContext(CartContext);
  const locationsContext = useContext(LocationsContext);
  const { created: cartCreated, onCreate: onCreateCart } = useCreateCart();
  const { updated: cartUpdated, onUpdate: updateCart } = useUpdateCart();
  const [checkFeeNow, setCheckFeeNow] = useState(false);
  useEffect(() => {
    if (_.isEmpty(locationsContext)) return;
    if (_.isEmpty(locationsContext.selectedLocation)) return;

    const populateAddress = {
      countryCode: locationsContext.selectedLocation.address.countryCode,
      country: locationsContext.selectedLocation.address.country,
      city: locationsContext.selectedLocation.address.city,
      province: locationsContext.selectedLocation.address.province,
    };
    if (_.isEmpty(cartContext.shippingAddress))
      cartContext.setShippingAddress({
        ...cartContext.shippingAddress,
        ...populateAddress,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsContext]);

  useEffect(() => {
    if (checkFeeNow !== true) return;
    if (cartContext.orderType !== "delivery") return;

    if (_.isEmpty(cartContext.cartToken)) onCreateCart();
    else updateCart("ADDRESS");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkFeeNow]);

  useEffect(() => {
    setCheckFeeNow(false);
    if (cartCreated === false && cartUpdated === false) return;

    if (cartContext.canDeliver) onHide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartCreated, cartUpdated]);

  const onSubmit = (e) => {
    e.preventDefault();
    setCheckFeeNow(true);
  };

  // =========================================================
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton={true}>
          <Modal.Title>
            {transMsg("deliveryDetails")}{" "}
            {checkFeeNow && <Spinner animation="border" variant="info" />}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CannotDeliverMessage />

          <Form onSubmit={onSubmit}>
            {process.env.GATSBY_GOOGLE_MAPS_API_KEY ? (
              <>
                <NewAutoAddressInput
                  uniqueKey="DeliveryAddressModal"
                  address={cartContext.shippingAddress}
                  disabled={checkFeeNow}
                  onDone={(address) => {
                    cartContext.setShippingAddress(
                      produce(cartContext.shippingAddress, (draft) => {
                        _.map(address, (v, k) => {
                          draft[k] = v;
                        });
                      })
                    );
                  }}
                />
              </>
            ) : (
              <>
                <AddressInput
                  uniqueKey="DeliveryAddressModal"
                  address={cartContext.shippingAddress}
                  setAddress={cartContext.setShippingAddress}
                  fieldAttributes={{
                    phone: { display: false },
                    firstName: { display: false },
                    lastName: { display: false },
                  }}
                />
              </>
            )}

            <div className="pt-5"></div>
            <ScheduleTime
              hoursType="delivery"
              label={transMsg("deliveryTime")}
            />

            <Button
              variant="secondary"
              type="submit"
              className="btn-height btn btn-lg btn-outline-primaryOFF btn-block text-uppercaseOFF font-weight-bold  round-btn theme-color-text theme-color-back"
            >
              {transMsg("done")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeliveryAddressModal;
