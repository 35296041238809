import React, { useContext, useEffect } from "react";
import { CartContext } from "../../context";
import _ from "lodash";
import { useAlert } from "react-alert";
import { useTrans } from "../../hooks";

const CannotDeliverMessage = ({ showAlert }) => {
  const transMsg = useTrans();
  const { canDeliver, orderType } = useContext(CartContext);
  const alert = useAlert();

  useEffect(() => {
    if (_.isUndefined(showAlert)) return;
    if (orderType !== "delivery") return;
    if (canDeliver) return;

    alert.error(transMsg("cannotDeliverMessage"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlert, orderType, canDeliver]);
  return (
    <>
      {orderType === "delivery" && !canDeliver && (
        <div className="text-danger h4">{transMsg("cannotDeliverMessage")}</div>
      )}
    </>
  );
};

export default CannotDeliverMessage;
