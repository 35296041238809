import React, { useEffect, useMemo, useState, useContext } from "react";
import { Form, ListGroup } from "react-bootstrap";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";
import produce from "immer";
import { GoogleMap, Marker } from "@react-google-maps/api";
import formatAddress from "../../utils/formatAddress";
import _ from "lodash";
import { useTrans } from "../../hooks";
import AddressInput from "./index";
import { LocationsContext } from "../../context";
import useOnclickOutside from "react-cool-onclickoutside";

const NewAutoAddressInput = ({ uniqueKey, address, onDone, disabled }) => {
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(address);
  const locationsContext = useContext(LocationsContext);

  useEffect(() => {
    onDone(selectedAddress);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress]);

  const onSelect = (suggestion) => {
    const { description, place_id } = suggestion;
    // console.log("description: ", description);
    const parameter = {
      // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
      placeId: place_id,
      // Specify the return data that you want (optional)
      fields: ["address_components", "geometry"]
    };

    getDetails(parameter)
      .then((details) => {
        // console.log("Details: ", details);
        let _address = { address_1: "", postalCode: "" };
        for (const component of details.address_components) {
          switch (component.types[0]) {
            case "street_number":
              _address.address_1 = component["short_name"] + " ";
              break;
            case "route":
              _address.address_1 += component["long_name"];
              break;
            case "administrative_area_level_1":
              _address.province = component["long_name"];
              break;
            case "country":
              _address.country = component["long_name"];
              break;
            case "postal_code":
              _address.postalCode = component["short_name"];
              break;
            case "locality":
              _address.city = component["long_name"];
              break;
            default:
          }
        }

        _address.lat = _.toString(details.geometry.location.lat());
        _address.long = _.toString(details.geometry.location.lng());
        _address.google_address = description;
        // console.log("lat: ", _address.lat, "long", _address.long);
        setIsAddressSelected(true);


        let pos = {
          lat: parseFloat(_address.lat),
          lng: parseFloat(_address.long)
        };

        let googleMapsDestinations = locationsContext.locations.map((location) => ({
          lat: parseFloat(location.address.lat),
          lng: parseFloat(location.address.long)
        }));

        const google = window.google;
        console.log("Calling Distance from NewAutoComplete");
        let service = new google.maps.DistanceMatrixService();

        service.getDistanceMatrix(
          {
            destinations: googleMapsDestinations,
            origins: [pos],
            travelMode: "DRIVING",
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidTolls: true
          },
          (response) => {
            let closesetDistance = response.rows[0].elements[0].distance.value;
            let closestLocation = locationsContext.locations[0];
            for (let i = 0; i < response.rows[0].elements.length; i++) {
              if (!_.isEmpty(locationsContext.locations[i].deliveryOptionsJson?.[selectedAddress.postalCode]) && !locationsContext.locations[i].deliveryOptionsJson?.[selectedAddress.postalCode]?.disable) {
                closestLocation = locationsContext.locations[i];
                break;
              }
              const element = response.rows[0].elements[i];
              if (element.distance.value < closesetDistance) {
                closestLocation = locationsContext.locations[i];
              }
            }

            // console.log(closestLocation);
            if (!_.isEmpty(closestLocation)) locationsContext.setSelectedLocation(closestLocation);
          }
        );

        // console.log(locationsContext.locations);
        // console.log(selectedAddress);

        const _selectedAddress = produce(selectedAddress, (draft) => {
          _.map(_address, (v, k) => {
            draft[k] = v;
          });
          //
        });
        setSelectedAddress(_selectedAddress);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  return (
    <>
      <SelectAddress uniqueKey={uniqueKey} selectedAddress={selectedAddress} onSelect={onSelect} disabled={disabled} required={true} />

      {(isAddressSelected || !_.isUndefined(selectedAddress.lat)) && (
        <>
          <Address2 uniqueKey={uniqueKey} selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} />
          <div className="mb-4"></div>
          <LatLongMap selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} onDone={onDone} />
        </>
      )}
    </>
  );
};

const SelectAddress = ({ uniqueKey, selectedAddress, onSelect, disabled, required }) => {
  const transMsg = useTrans();

  const locationsContext = useContext(LocationsContext);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      //   input: address?.google_address || "",
      /* Define search scope here */
      type: ["address"],
      //   radius: 50000,
      componentRestrictions: {
        country: _.lowerCase(locationsContext?.selectedLocation?.address?.countryCode) || "ca"
      }
    },
    callbackName: "initMap",
    debounce: 400
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  useEffect(() => {
    if (_.isEmpty(selectedAddress.lat)) return;
    setValue(formatAddress(selectedAddress, true, true), false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress]);

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <ListGroup.Item
          key={place_id}
          style={{ cursor: "pointer" }}
          onClick={async () => {
            // console.log(suggestion);
            onSelect(suggestion);
            clearSuggestions();
          }}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>{" "}
        </ListGroup.Item>
      );
    });
  return (
    <>
      {" "}
      <div className="row" ref={ref}>
        <div className={`col-md-12`}>
          <div className="form-label-group">
            <Form.Control
              as="input"
              type="text"
              id={`address_${uniqueKey}`}
              placeholder=""
              className="require"
              // style={{ opacity: _.isEmpty(selectedAddress.lat) ? 1 : 0.5 }}
              name="address"
              onChange={({ target: { value } }) => {
                if (ready) setValue(value, value.length > 3);
              }}
              required
              value={value}
            />

            <Form.Label htmlFor={`address_${uniqueKey}`}>
              {transMsg("pleaseEnterAddress")} {_.isEmpty(selectedAddress?.lat) && <span className="text-danger pr-2">*</span>}
            </Form.Label>
          </div>
          {/* We can use the "status" to decide whether we should display the dropdown or not */}
          {status === "OK" && <ListGroup style={{ position: "absolute", zIndex: 1 }}>{renderSuggestions()}</ListGroup>}
        </div>
      </div>
    </>
  );
};

const Address2 = ({ uniqueKey, selectedAddress, setSelectedAddress }) => {
  return (
    <>
      <AddressInput
        uniqueKey={uniqueKey}
        address={selectedAddress}
        setAddress={setSelectedAddress}
        fieldAttributes={{
          phone: { display: false },
          firstName: { display: false },
          lastName: { display: false },
          country: { display: false },
          city: { display: false },
          address_1: { display: false, require: false },
          address_2: { display: true, require: false, col: "6" },
          postalCode: { display: false, require: false },
          province: { display: false }
        }}
      />
    </>
  );
};

const LatLongMap = ({ selectedAddress, setSelectedAddress, onDone }) => {
  const mapCenter = useMemo(() => {
    return {
      lat: selectedAddress?.lat ? _.toNumber(selectedAddress?.lat) : -122.0841,
      lng: selectedAddress?.long ? _.toNumber(selectedAddress?.long) : 37.4221
    };
  }, [selectedAddress]);
  return (
    <>
      {!_.isEmpty(selectedAddress?.lat) && (
        <>
          <div className="row">
            <div className="col-12" style={{ height: 200 }}>
              <GoogleMap
                mapContainerStyle={{
                  height: "200px"
                }}
                options={{
                  clickableIcons: false,
                  gestureHandling: "none",
                  fullscreenControl: false,
                  mapTypeControl: false,
                  streetViewControl: false,
                  zoomControl: true
                }}
                center={mapCenter}
                zoom={18}
              >
                {/* Child components, such as markers, info windows, etc. */}
                <Marker
                  position={mapCenter}
                  draggable={true}
                  onDragEnd={(e) => {
                    const _address = produce(selectedAddress, (draft) => {
                      draft.lat = _.toString(e.latLng.lat());
                      draft.long = _.toString(e.latLng.lng());
                    });
                    setSelectedAddress(_address);
                  }}
                />
              </GoogleMap>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NewAutoAddressInput;
